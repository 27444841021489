<template>
  <svg viewBox="0 0 32 16" xmlns="http://www.w3.org/2000/svg">
    <slot name="icon-title" />
    <path d="M0 0L32 0L32 16L0 16L0 0Z" fill="#fff" />
    <path d="M0 0L32 0L32 1.23L0 1.23L0 0Z" fill="#b22234" />
    <path d="M0 2.46L32 2.46L32 3.69L0 3.69L0 2.46Z" fill="#b22234" />
    <path d="M0 4.92L32 4.92L32 6.15L0 6.15L0 4.92Z" fill="#b22234" />
    <path d="M0 7.38L32 7.38L32 8.61L0 8.61L0 7.38Z" fill="#b22234" />
    <path d="M0 9.85L32 9.85L32 11.08L0 11.08L0 9.85Z" fill="#b22234" />
    <path d="M0 12.31L32 12.31L32 13.54L0 13.54L0 12.31Z" fill="#b22234" />
    <path d="M0 14.77L32 14.77L32 16L0 16L0 14.77Z" fill="#b22234" />
    <path d="M0 0L12.8 0L12.8 8.61L0 8.61L0 0Z" fill="#3c3b6e" />
  </svg>
</template>
